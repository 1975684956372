/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/* body,
html {
  height: 100vh;
} */
/* body,
html {
  height: 100%;
  margin: 0;
} */
html,body {
  /* height: 100%; */
  /* margin: 0; */
  background: url(../public/images/common1.png);
  /* -webkit-background-size: cover; */
  /* -moz-background-size: cover; */
  /* -o-background-size: cover; */
  background-size: cover;
  background-position: center;
  background-position: center;
  /* height: 100vh; */
   overflow-x: hidden; 



}

/* .backGround {


  background: url(../public/images/common.png) no-repeat center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  height: 100vh;
} */

.backGround {
  /* background-image: url(../public/images/common.png); */

  /* Full height */
  height: 100vh;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

h3.character_ava {
  /* text-align: center; */
  /* font-size: px; */
  font-weight: 500;
  color: white;
}

.myChart {
  height: 80vh;
  padding: 100px;
}

.myChart3 {
  height: 90vh;
  padding: 200px;
}

.inv_head{
  font-weight: bold;
}